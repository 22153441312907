@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-family: "Clash Display", sans-serif;
}

p,
button,
a,
input {
  font-family: "Satoshi", sans-serif;
}

.fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.tooltip {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px, 30px, 40px, 5px, rgba(0, 0, 0, 0.5);
  text-align: center;
}

.box {
  @apply py-1 rounded-sm px-2 bg-gray-300 text-gray-800 max-w-[80px];
}

.input {
  @apply bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md;
}
